/** @format */
import {useMutation} from 'react-query';

import {apiV1Url} from '../../utils/commonConstants';
import {EUserType} from "./createCtoryTypes";

interface TCreateStoryParams {
	email: string,
	name: string,
	topic: string,
	userType: EUserType,
	file: File | null,
	language: string,
}
const createStoryRequest = async ({email, file, name, topic, userType, language}:TCreateStoryParams) => {
	
	const formData = new FormData();
	
	formData.set('email', email);
	formData.set('name', name);
	formData.set('topic', topic);
	formData.set('user_type', userType);
	formData.set('language', language);
	if (file !== null) {
		formData.set('file', file);
	}
	
	const response = await fetch(`${apiV1Url}/user/run_task`, {
		method: 'POST',
		body: formData,
	});
	
	if (!response.ok) {
		throw await response.json();
	} else {
		return await response.json();
	}
}

export const useCreateStory = () => {
	const mutation = useMutation<{ token: string }, unknown, TCreateStoryParams>({
		mutationFn: createStoryRequest,
	});
	
	return {
		createStory: mutation.mutate,
		isStoryCreating: mutation.isLoading,
		isStoryCreatingError: mutation.isError,
		storyCreationError: mutation.error,
	}
}