/** @format */

import React, {FC, useCallback} from 'react';

import classes from './scanPageStepForm.module.scss';
import {TCreateStoryPageStepProps} from "../createStoryPageTypes";
import Stepper from "../../../components/Stepper";
import Button from "../../../components/Button";

interface PasteWebsiteTextFormProps extends TCreateStoryPageStepProps {
	
}

const PasteWebsiteTextForm: FC<PasteWebsiteTextFormProps> = props => {
	const {
		onBack,
		onSubmit,
	} = props;
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	return (
		<form
			className={classes.paste_website_text_form}
			action="#"
			onSubmit={handleSubmit}
		>
			<h1 className={classes.title}>Paste Website Text</h1>
			<textarea
				className={classes.textarea}
				placeholder="Paste text sections from websites here"
			/>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>Back</Button>
				<Button type="submit">Next</Button>
			</div>
		</form>
	)
}

export default PasteWebsiteTextForm;