/** @format */

export default class {
	static playground = () => '/__'
	//

	static homePage = () => '/';

	static createStoryFlow = () => '/create-a-story';

	static pendingResult = (sessionId = ':sessionId') => `/pending-result/${sessionId}`;

	static learningMaterialsEditor = (sessionId = ':sessionId') => `/learning-materials-editor/${sessionId}`;
}