/** @format */

// export const baseUrl = window.location.origin;
export const baseUrl = 'https://www.ainclusive.education/';
export const apiUrl = `${baseUrl}/api`;
export const apiV1Url = `${apiUrl}/v1`;

export const languageOptions = [
	{value: 'en', label: 'English'},
	{value: 'he', label: 'Hebrew'},
]