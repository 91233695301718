/** @format */

export enum EUserType {
	teacher = 'Teacher',
	parent = 'Parent',
	lsa = 'LSA'
}

export enum ESourceType {
	classroomBook = 'ClassroomBook',
	classroomBooklet = 'ClassroomBooklet',
	websiteText = 'WebsiteText',
}