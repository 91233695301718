import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, HashRouter} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from 'react-query';
import {I18nextProvider} from "react-i18next";

import App from './App';

import i18n from "./utils/i18n";

import './index.scss';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		}
	}
})

root.render(
	<React.StrictMode>
		<HashRouter>
			<QueryClientProvider client={queryClient}>
				<I18nextProvider i18n={i18n}>
					<App/>
				</I18nextProvider>
			</QueryClientProvider>
		</HashRouter>
	</React.StrictMode>
);
