/** @format */

import React, {FC} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";

import HomePage from "./pages/HomePage";
import PlayGroundPage from "./pages/PlayGroundPage";

import routes from "./utils/routes";
import CreateStoryPage from "./pages/CreateStoryPage";
import PendingResultPage from "./pages/PendingResultPage/PendingResultPage";
import LearningMaterialsEditorPage from "./pages/LearningMaterialsEditorPage/LearningMaterialsEditorPage";

import {AnimatePresence} from 'framer-motion'

const Router: FC = props => {
	const {} = props;
	const location = useLocation();
	
	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				<Route path={routes.homePage()} element={<HomePage/>}/>
				<Route path={routes.playground()} element={<PlayGroundPage/>}/>
				<Route path={routes.createStoryFlow()} element={<CreateStoryPage/>}/>
				<Route path={routes.pendingResult()} element={<PendingResultPage/>}/>
				<Route path={routes.learningMaterialsEditor()} element={<LearningMaterialsEditorPage/>}/>
			</Routes>
		</AnimatePresence>
	)
}

export default Router;