/** @format */

import React, {FC, useCallback} from 'react';

import classes from './sessionsStepForm.module.scss'
import Stepper from "../../../components/Stepper";
import Button from "../../../components/Button";
import CheckboxBlock from "../../../components/CheckboxBlock";
import {TCreateStoryPageStepProps} from "../createStoryPageTypes";
import {useTranslation} from "react-i18next";

interface SessionsStepFormProps extends TCreateStoryPageStepProps{
}

const SessionsStepForm: FC<SessionsStepFormProps> = props => {
	const {
		onBack,
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	return (
		<form
			action="#"
			className={classes.session_form}
			onSubmit={handleSubmit}
		>
			<h3 className={classes.description}>
				{t('creation.session_step.title')}
			</h3>
			<div className={classes.session_grid}>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					checked
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.learning_of_new_vocabulary')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					checked
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.pictures_to_match_ideas_from_the_text')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					checked
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.story_board_to_practice_understanding_of_events_and_sequence')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					checked
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.a_close_to_exercise_fill_in_the_blank_skill_of_a_new_vocabulary')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					checked
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.quiz_with_yes_no')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					className={classes.session_checkbox_block}
					disabled
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.short_videos_on_subject')}
					</p>
				</CheckboxBlock>
			</div>
			
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default SessionsStepForm;