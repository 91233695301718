import React, {FC} from 'react';

import Button from '../../components/Button';
import Main from '../../components/Main';
import Header from '../../components/Header';

import classes from './learningMaterialsEditorPage.module.scss';

import printIconImg from './printIcon.svg'


interface LearningMaterialsEditorPageProps {

}

const LearningMaterialsEditorPage: FC<LearningMaterialsEditorPageProps> = () => {

    return (
        <Main className={classes.main}>
            <Header/>
            <div className={classes.learning_materials_editor_page}>
                <div className={classes.header}>
                    <h1 className={classes.title}>Lesson name</h1>
                    <Button variant="text">
                        <img src={printIconImg} alt="print"/>
                        Print
                    </Button>
                </div>
                <Button variant="primary">123</Button>
                <Button variant="outline">123</Button>
            </div>
        </Main>
    )
}

export default LearningMaterialsEditorPage;